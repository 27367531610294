import { Select } from "antd"
import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"

function ChannelsSelection({ informationKey, channelCode, channel, ...props }) {
    const history = useHistory()

    const [channels, setChannels] = useState([])

    channelCode = channelCode || "all"

    useEffect(
        () => {
            if (!!channel) {
                channel.on("channel-data", ({ data }) => {
                    setChannels(data)
                })
            }
        }, [channel])

    const onSelect = (value) => {
        if (value === "all") {
            history.push({
                pathname: `/ops/settings/${informationKey}`
            })
        } else {
            history.push({
                pathname: `/ops/settings/${informationKey}/${value}`
            })
        }
    }

    return <div>
        <Select showSearch style={{ width: 400 }} onSelect={onSelect} value={channelCode}>
            <Select.Option value="all">默认</Select.Option>
            {channels.map(channel => (
                <Select.Option key={channel.key} value={channel.key}>{channel.name} ({channel.key})</Select.Option>
            ))}
        </Select>
    </div>
}

export default ChannelsSelection

