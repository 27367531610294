import React, { useState, useEffect } from "react"
import { List, Image, Radio, Pagination, Switch, message } from 'antd'
import useLocalStorageState from 'use-local-storage-state'
import LocalTimeAgo from "../../../components/local_time_ago";
import ImageWithPoints from '../../image_with_points'
import dayjs from 'dayjs'
import "./Xun-Ai.css"

// 当评价页数变更时执行
function handleTranPageChange(props, business, status, settranPagedData, page, pageSize) {

    if (status === "all")
        status = null
    props.pushEvent("xun-ai-transaction-list", { business, status, page, page_size: pageSize })
        .receive("ok", (data) => {
            settranPagedData(data)
        })
}

function statusSpan(text) {
    switch (text) {
        case "success":
            return <span style={{ color: "green" }}>已出报告</span>
        case "fail":
            return <span style={{ color: "red" }}>检测失败</span>
        case "set_up":
            return <span style={{ color: "grey" }}>等待提交</span>
        case "wait":
            return <span style={{ color: "orange" }}>等待报告中</span>
        case "continuous":
            return <span style={{ color: "orange" }}>资料不全</span>

        default:
            break;
    }
}


function XunAi(props) {
    // 设备分页
    const [tranPagedData, settranPagedData] = useState({})
    // 标签记录
    const [business, setBusiness] = useLocalStorageState('user_information.tabs.xun-ai', undefined)
    // 标签记录
    const [status, setStatus] = useLocalStorageState('user_information.tabs.xun-ai.filter', 'all')

    useEffect(() => {
        handleTranPageChange(props, business, status, settranPagedData, 1, 10)
    }, [props.userId])


    // 业务变更
    function handleBusinessChange(business, page, size) {
        setBusiness(business)
        handleTranPageChange(props, business, status, settranPagedData, page, size)
    }

    // 状态变更
    function handleStatusChange(status, page, size) {
        setStatus(status)
        handleTranPageChange(props, business, status, settranPagedData, page, size)
    }

    // 购买状态变更
    function handlePurchasedChange(id, purchased) {
        props.pushEvent("xun-ai-transaction-purchased", { id, purchased }).receive("ok", (data) => {
            message.success("购买状态变更成功")
            handleBusinessChange(business, tranPagedData.page_number, tranPagedData.page_size)
        })
    }

    function renderImage(item) {
        switch (item.business) {
            case "舌诊":
                return <Image width={60} src={item.material.tongueFrontImgPath} shape="square" />
            case "穴位检测":
                const report = item.report;
                let acupoint_list = []
                for (let part in report?.acupoint_list || []) {
                    const list = report?.acupoint_list[part]
                    const positions = list.map(i => i.position)
                    acupoint_list = acupoint_list.concat(positions)
                }

                return <ImageWithPoints width={60} src={report?.pic_url} positions={acupoint_list} />

            case "手诊":
                return <>
                    <Image width={60} src={item.material.leftHandImgPath} shape="square" />
                </>
            case "面诊":
                return <>
                    <Image width={60} src={item.material.faceImgPath} shape="square" />
                </>
            default:
                break;
        }

    }

    return <div className="component user-information-tabs-xun-ai">
        <p>
            <Radio.Group value={business} onChange={(e) => handleBusinessChange(e.target.value, 1, 10)} size="small" style={{ margin: "0 10px 0 0" }}>
                <Radio.Button value={undefined}>全部</Radio.Button>
                <Radio.Button value="舌诊">舌诊</Radio.Button>
                <Radio.Button value="穴位检测">穴位识别</Radio.Button>
                <Radio.Button value="手诊">手诊</Radio.Button>
                <Radio.Button value="面诊">面诊</Radio.Button>
                <Radio.Button value="体质分析">体质分析</Radio.Button>
            </Radio.Group>
        </p>

        <p>
            <Radio.Group value={status} onChange={(e) => handleStatusChange(e.target.value, 1, 10)} size="small" style={{ margin: "0 5px 0 0" }}>
                <Radio.Button value="all">全部</Radio.Button>
                <Radio.Button value="success">已出报告</Radio.Button>
                <Radio.Button value="fail">检测失败</Radio.Button>
            </Radio.Group>
        </p>
        <Pagination current={tranPagedData?.page_number} size="small" total={tranPagedData?.total_entries}
            showQuickJumper
            pageSize={tranPagedData?.page_size || 10}
            onChange={(page, size) => handleBusinessChange(business, page, size)} />
        <List
            className="list-item"
            dataSource={tranPagedData.entries}
            renderItem={item => (
                <List.Item key={item.id}>
                    <List.Item.Meta
                        title={<div style={{ display: "flex", justifyContent: "space-between" }}> <span>{statusSpan(item.status)} {item.business} </span><LocalTimeAgo date={dayjs(item.inserted_at).format("YYYY-MM-DD HH:mm")} /></div>}
                        avatar={renderImage(item)}
                        description={<div><div>{item.summary}</div><div style={{ display: "flex", justifyContent: "flex-end" }}><Switch checked={item.purchased} checkedChildren="已单独购买" unCheckedChildren="未单独购买" onChange={(checked) => handlePurchasedChange(item.id, checked)} /></div></div>}
                    />
                </List.Item>
            )}
        >
        </List>

        <Pagination showQuickJumper current={tranPagedData?.page_number} size="small" total={tranPagedData?.total_entries}
            pageSize={tranPagedData?.page_size || 10}
            onChange={(page, size) => handleBusinessChange(business, page, size)} />
    </div>
}

export default XunAi;