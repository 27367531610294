import React, { useState, useEffect } from "react"
import { Tag, List, Button, Form, Modal, Input, Alert, message, Divider, Switch, Radio } from 'antd'
import { MobileOutlined, TabletOutlined, MergeCellsOutlined, UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs'

import { Link } from 'react-router-dom';
import UserAvatar from '../UserAvatar';

import RequireRolesOr from '../../require_roles_or'

// 当评价页数变更时执行
function handleDevicePageChange(props, setDevicePagedData, page, pageSize) {
    props.pushEvent("device-list", { page, page_size: pageSize })
        .receive("ok", (data) => {
            setDevicePagedData(data)
        })
}

function deviceType(type) {
    switch (type) {
        case "mobile":
            return <Tag color={"green"} icon={<MobileOutlined />}>手机</Tag>
        case "tablet":
            return <Tag color={"blue"} icon={<TabletOutlined />}>平板</Tag>
        default:
            break;
    }
}


// 处理用户合并迁移
function handleIdentityMigrate(form, props, account, sourceUserId, targetUserId) {
    Modal.confirm({
        title: '迁移用户',
        content: <>
            <Alert message="确定迁移吗？迁移凭据并不会合并两个账号的会员时长。" banner type="warning" showIcon />
            <p></p>
            <Form form={form} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off">
                <Form.Item label="账号">
                    <span className="ant-form-text">{account}</span>
                </Form.Item>
                <Form.Item label="确认密码" name="password" rules={[{ required: true, message: '密码不能为空' }]}>
                    <Input.Password />
                </Form.Item>
            </Form>
        </>,
        onOk: (close) => {
            form
                .validateFields()
                .then(values => {
                    form.resetFields();
                    props.pushEvent("identity-migrate", { source_user_id: sourceUserId, target_user_id: targetUserId, account, password: values.password })
                        .receive("ok", () => {
                            close()
                            message.success("迁移成功")
                        })
                })
                .catch(info => {
                    console.log('Validate Failed:', info);
                });
            return false
        }
    });
}

function Devices(props) {
    // 设备分页
    const [devicePagedData, setDevicePagedData] = useState([])
    // 当前登录的凭证
    // const [currentEvidence] = useLocalStorageState('current-evidence', null)
    const currentEvidence = JSON.parse(localStorage.getItem('current-evidence') || 'null')
    // 表单ref
    const [form] = Form.useForm();

    useEffect(() => {
        handleDevicePageChange(props, setDevicePagedData, props, 1, 10)
    }, [props.userId])


    // 处理设备开关
    function handleDeviceSwitchChange(props, deviceId, available) {
        props.pushEvent("device-switch", { device_id: deviceId, available })
            .receive("ok", () => {
                message.success("操作成功")
                handleDevicePageChange(props, setDevicePagedData, props, 1, 10)
            })
    }

    // 处理设备类型
    function handleDeviceTypeChange(props, deviceId, type) {
        props.pushEvent("device-type", { device_id: deviceId, type })
            .receive("ok", () => {
                message.success("操作成功")
                handleDevicePageChange(props, setDevicePagedData, props, 1, 10)
            })
    }


    return <List
        className="list-item"
        dataSource={devicePagedData}
        renderItem={item => (
            <List.Item key={item.id}>
                <div className="title">
                    <span><Switch checked={item.available} onChange={(value) => handleDeviceSwitchChange(props, item.id, value)} checkedChildren="启用" unCheckedChildren="禁用" /></span> <Divider type="vertical" />
                    <Radio.Group value={item.type} size="small" buttonStyle="solid" onChange={(e) => handleDeviceTypeChange(props, item.id, e.target.value)}>
                        <Radio.Button value="mobile"><MobileOutlined />手机</Radio.Button>
                        <Radio.Button value="tablet"><TabletOutlined />平板</Radio.Button>
                    </Radio.Group> <h3>{item.nickname} </h3>
                </div>
                <div><Tag>IMEI: {item.imei}</Tag> <Tag>IDFA: {item.idfa}</Tag>  </div>
                <div><Tag>最近登录时间：{dayjs(item.latest_sign_in_at).format("YYYY-MM-DD HH:mm")} </Tag> <Tag>设备激活时间：{dayjs(item.active_at).format("YYYY-MM-DD HH:mm")}</Tag>  </div>

                <List
                    className="ref-user-list"
                    style={{ width: "100%", paddingLeft: 30 }}
                    dataSource={item.ref_users}
                    renderItem={(user) => (
                        <List.Item key={user.id} actions={[
                            user.id.toString() !== props.userId && <Link target="_blank" to={`/operation/${user.id}`}><UserOutlined /></Link>,
                            RequireRolesOr.allow(["acupoint:role::operator"]) && user.id.toString() !== props.userId ? <Button onClick={() => handleIdentityMigrate(form, props, currentEvidence.account, props.userId, user.id)} icon={<MergeCellsOutlined />} type="link">迁移凭据</Button> : null
                        ]} >
                            <List.Item.Meta
                                style={{ width: "100%" }}
                                avatar={<UserAvatar disabled size={40} id={user.id} avatar={user.avatar} vip={user.group === "vip"} />}
                                title={<span>{user.nickname} <span className="secondary">@{user.id} {user.id.toString() === props.userId ? <sup>自己</sup> : ""}</span> </span>}
                                description={<div>
                                    <div><Tag>创建时间：{dayjs(user.created_at).format("YYYY-MM-DD HH:mm")}</Tag></div>
                                </div>}
                            />
                        </List.Item>
                    )}
                >
                </List>
            </List.Item>
        )}
    >
    </List>
}

export default Devices;