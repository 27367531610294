import { Form, Input, InputNumber, Switch, message, Tooltip, Button, Divider } from "antd"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Menu as MenuAction } from "../../store/global/actions"
import ChannelsSelection from "./components/ChannelsSelection"
import "./PhoneSimulator.css"

const INFORMATION_KEY = "aiSwitcher"

function AISwitcher(props) {
    let [data, setData] = useState(null)
    let [channel, setCannel] = useState(null)
    let [form] = Form.useForm()
    props.setActiveKey("SETTING-AI-SWITCHER")

    // 获取路由传递的channel_code
    const channelCode = props.match?.params?.channel_code

    useEffect(() => {
        const _channel = props.socket.channel(`operation:settings:${INFORMATION_KEY}`, { channel_code: channelCode })
        _channel.join()
        _channel.on("data", ({ data }) => {
            setData((data[0] || { content: "{}" }).content)
            form.setFieldsValue(JSON.parse((data[0] || { content: "{}" }).content))
        })
        setCannel(_channel)

        return () => {
            !!_channel && _channel.leave()
        }
    }, [channelCode])

    const onSave = (values) => {
        channel.push("set", { [INFORMATION_KEY]: JSON.stringify(values) })
            .receive("ok", () => message.success("保存成功"))
    }

    const onClear = () => {
        channel.push("clear", { key: INFORMATION_KEY })
            .receive("ok", () => message.success("清除成功"))
    }

    return <div className="page settings" style={{ flexDirection: "column", display: "flex", height: "100%" }}>
        <h1>AI 诊断设置</h1>


        <div style={{ display: "flex", flexGrow: 1 }}>
            {!!data &&
                <Form form={form} style={{ width: 600 }} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off" onFinish={onSave}>
                    <Form.Item label="渠道" help={!!channelCode && "注意：以下数据也采用渠道数据优先，否则使用默认数据。 这些数据默认也来自于默认数据"}>
                        <ChannelsSelection informationKey={INFORMATION_KEY} channelCode={channelCode} channel={channel} />
                    </Form.Item>
                    <Form.Item label="会员每日可用次数" name="vip_balance" rules={[{ required: true, message: '不能为空' }]} help="完全免费设置9999即可。此项不受AI开关影响">
                        <InputNumber min={0} max={9999} />
                    </Form.Item>
                    <Form.Item label="非会员总可用次数" name="potential_balance" rules={[{ required: true, message: '不能为空' }]} help="此项不受AI开关影响">
                        <InputNumber min={0} max={9999} />
                    </Form.Item>
                    <Form.Item label="付费弹框跳过次数" name="pay_skip_times" rules={[{ required: true, message: '不能为空' }]} help="会员弹窗打开第几次展示单次付费价格">
                        <InputNumber min={0} max={9999} />
                    </Form.Item>
                    <Divider />
                    <Form.Item label="是否开启" name="enable" valuePropName="checked" rules={[{ required: true, message: '不能为空' }]}>
                        <Switch />
                    </Form.Item>
                    <Form.Item label="关闭提示语" name="alert" rules={[{ required: true, message: '不能为空' }]} >
                        <Input.TextArea autoSize />
                    </Form.Item>
                    <Form.Item wrapperCol={{ xs: { span: 8, offset: 8, } }}>
                        <Button.Group>
                            <Button size="small" type="primary" htmlType="submit">保存</Button>
                            {!!channelCode && <Tooltip title="删掉渠道数据而使用默认数据">
                                <Button size="small" onClick={onClear}>清除渠道数据</Button>
                            </Tooltip>}
                        </Button.Group>
                    </Form.Item>
                </Form>
            }
        </div>
    </div>
}


const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AISwitcher)