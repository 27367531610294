import { Form, Select, DatePicker, Table, message, Checkbox, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import dayjs from "dayjs"

const { Column } = Table;

const Row = (props) => {
    if (!!props['data-row-key']) {
        const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
            id: props['data-row-key'],
        });

        const style = {
            ...props.style,
            transform: CSS.Translate.toString(transform),
            transition,
            cursor: 'move',
            ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
        };

        return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
    }
    else {
        return <tr {...props} style={{ cursor: "no-drop" }} />;
    }


};

export default function PricingChannelView({ socketChannel, channels, versions }) {

    const [formData, setFormData] = useState({ channel_code: "HUAWEI", active_at: dayjs(), version_code: versions[0]?.code })
    const [channelPricings, setChannelPricings] = useState([])
    const [form] = Form.useForm()

    useEffect(() => {
        handleGetChannelPricings(formData)
    }, [])

    // 处理获取渠道金额
    const handleGetChannelPricings = (params) => {
        setFormData(params)
        socketChannel.push("channel-pricings", { channel: params.channel_code, active_at: params.active_at, version_code: params.version_code })
            .receive("ok", ({ data }) => {
                setChannelPricings(data)
            })
    }

    // 设置渠道默认
    const handleSetGroupDefault = (channel_code, id, checked) => {
        socketChannel.push("set-channel-group-default", { channel_code, id, checked })
            .receive("ok", () => {
                message.success("操作成功")
                handleGetChannelPricings(formData)
            })
    }

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            message.loading({ key: "sort-submit", content: "提交中" })

            setChannelPricings((prev) => {
                const activeIndex = prev.findIndex((i) => i.pricing_channel_id === active.id);
                const overIndex = prev.findIndex((i) => i.pricing_channel_id === over?.id);

                const data = arrayMove(prev, activeIndex, overIndex).map((item, i) => ({ ...item, order: i }))

                socketChannel.push("channel-pricing-order-resort", { sort: data.map((item) => ({ id: item.pricing_channel_id, order: item.order })) })
                    .receive("ok", () => {
                        message.success({ key: "sort-submit", content: "排序成功" })
                    })

                return data
            });
        }
    };
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 1,
            },
        }),
    );

    return <>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} initialValues={formData} onValuesChange={(_, allValues) => handleGetChannelPricings(allValues)}>
            <Form.Item label="渠道" name="channel_code">
                <Select showSearch>
                    {
                        channels.map(item => <Select.Option label={item.name} key={item.key} value={item.key}>{item.name}</Select.Option>)
                    }
                </Select>
            </Form.Item>
            <Form.Item label="版本" name="version_code">
                <Select>
                    {
                        versions.map(item => <Select.Option key={item.key} value={item.code}>{item.name}({item.code})</Select.Option>)
                    }
                </Select>
            </Form.Item>
            <Form.Item label="激活时间" name="active_at">
                <DatePicker format="YYYY-MM-DD HH:mm" showTime></DatePicker>
            </Form.Item>
        </Form>

        <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext
                items={channelPricings.map((i) => i.pricing_channel_id)}
                strategy={verticalListSortingStrategy}
            >
                <Table dataSource={channelPricings} size="middle" components={{ body: { row: Row } }} rowKey={(i) => i.pricing_channel_id}>
                    <Column title="展示名" dataIndex="display_name" key="display_name" />
                    <Column title="售价" dataIndex="amount" key="amount" width={100} render={text => <span style={{ color: "green" }}>{text / 100} 元</span>} />
                    <Column title="原价" dataIndex="originAmount" key="originAmount" render={text => <span style={{ color: "#aaa" }}>{text / 100} 元</span>} />
                    <Column title="小标题" dataIndex="slogan" key="slogan" />
                    <Column title="排序" dataIndex="order" key="order" />
                    <Column title="默认" dataIndex="group_default" key="isDefault" render={(text, item) =>
                        <Tooltip title={item.pricing_channel_id ? "设置当前价格为默认价格" : "由于本渠道无渠道价格，所以无法设置为默认价格"}>
                            <Checkbox checked={item.group_default || item.isDefault} disabled={!item.pricing_channel_id} onChange={(e) => handleSetGroupDefault(formData.channel_code, item.pricing_channel_id, e.target.checked)} />
                        </Tooltip>
                    } />
                </Table>
            </SortableContext>
        </DndContext>
    </>
}