import { Divider, Input, message } from "antd"
import { useEffect, useState } from "react"
import { Button } from "antd"
import { connect } from "react-redux"
import { Menu as MenuAction } from "../../store/global/actions"
import "./PhoneSimulator.css"

const INFORMATION_KEY = "customServiceNotice"


function CustomerServiceNotice(props) {
    let [data, setData] = useState({})
    let [channel, setCannel] = useState(null)
    let [text, setText] = useState(null)

    props.setActiveKey("SETTING-CUSTOMER-SERVICE")

    useEffect(
        () => {
            const _channel = props.socket.channel(`operation:settings:${INFORMATION_KEY}`)
            _channel.join()
            _channel.on("data", ({ data }) => {
                setData(data)
                setText(data[0].content)
            })
            setCannel(_channel)

            return () => {
                !!_channel && _channel.leave()
            }
        }, []
    )

    const onSave = (text) => {
        channel.push("set", { [INFORMATION_KEY]: text })
            .receive("ok", () => { message.success({ content: "保存成功" }) })
    }

    return <div className="page settings" style={{ flexDirection: "column", display: "flex", height: "100%" }}>

        <div style={{ display: "flex", flexGrow: 1 }}>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }} >
                <div className="phone-wraper" >
                    <div className="title" >我的客服</div>
                    <div className="body" >
                        {!!text && <>
                            <marquee className="top-marquee">{text}</marquee>
                            <div className="alert" >
                                <h3>温馨提示</h3>
                                <div style={{ flexGrow: 1 }}>{text}</div>
                                <div className="btn-iknow" >我知道了</div>
                            </div>
                        </>}
                    </div>
                    <div className="footer"></div>
                </div>
            </div>
            <Divider type="vertical" style={{ height: "100%" }} />
            <div style={{ flexGrow: 1 }}>
                <h1>配置 - 客服弹窗提示 </h1>
                <Input.TextArea cols={5} value={text} onChange={e => setText(e.target.value)} />
                <br />
                <br />

                <Button type="primary" block onClick={() => onSave(text)}>保存</Button>
                <Button block type="ghost" onClick={() => {
                    setText(""); onSave("")
                }}>不弹框提示</Button>
            </div>
            <Divider type="vertical" style={{ height: "100%" }} />
            <div>
                <h2>快速设置（不会自动保存）</h2>
                <p><Button onClick={() => setText("客服服务时间为工作日9:00 - 21:00，其余时间无法及时响应，敬请谅解")}>客服服务时间为工作日9:00 - 21:00，其余时间无法及时响应，敬请谅解</Button></p>
                <p><Button onClick={() => setText("节假日期间客服可能不在线，无法及时响应，请您谅解")}>节假日期间客服可能不在线，无法及时响应，请您谅解</Button></p>
            </div>
        </div>
    </div>
}


const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerServiceNotice)