import { HashRouter as Router, Route } from "react-router-dom";
import { Menu as MenuAction } from '../store/global/actions'
import { connect } from 'react-redux';
import SideMenu from "./components/side_menu"
import React from "react";
import RequireRolesOr from '../components/require_roles_or'
import Member from "./Member";
import HotSearch from "./HotSearch";
import LaunchScreen from "./LaunchScreen";
import UserFeedBack from "./UserFeedBack";
import CustomerServiceNotice from "./Setting/CustomerServiceNotice";
import HealthGuidance from "./Setting/HealthGuidance"
import HomeBannerAD from "./Setting/HomeBannerAD"
import AISwitcher from "./Setting/AISwitcher"
import GraphSuggest from "./GraphSuggest"
import Version from "./Version"
import AISearchSetting from "./Setting/AISearchSetting"

import NotificationChannel from "./Notification/Channel"
import NotificationScene from "./Notification/Scene"
import NotificationMessage from "./Notification/Message"

import RuntimeScheduler from "./RuntimeScheduler";

class Operation extends React.Component {

    componentDidMount() {
        // this.props.setActiveKey("OPERATION")
    }

    render() {
        return <Router>
            <RequireRolesOr require={["acupoint:role::customer-service"]}>
                <div className="module module-statistics">
                    <div className="side-menu">
                        <SideMenu />
                    </div>
                    <div className="content">
                        <RequireRolesOr require={["acupoint:role::customer-service"]}>
                            <Route path="/ops/member" exact component={Member}></Route>
                            <Route path="/ops/hot-search" exact component={HotSearch}></Route>
                            <Route path="/ops/launch-screen" exact component={LaunchScreen}></Route>
                            <Route path="/ops/feedback/:type" exact component={UserFeedBack}></Route>
                            <Route path="/ops/graph/suggest" exact component={GraphSuggest}></Route>
                            <Route path="/ops/runtime_scheduler" exact component={RuntimeScheduler}></Route>
                            <Route path="/ops/settings/customServiceNotice" exact component={CustomerServiceNotice}></Route>
                            <Route path="/ops/settings/customServiceNotice/:channel_code" exact component={CustomerServiceNotice}></Route>
                            <Route path="/ops/settings/healthGuidance" exact component={HealthGuidance}></Route>
                            <Route path="/ops/settings/healthGuidance/:channel_code" exact component={HealthGuidance}></Route>
                            <Route path="/ops/settings/homeBannerAD" exact component={HomeBannerAD}></Route>
                            <Route path="/ops/settings/homeBannerAD/:channel_code" exact component={HomeBannerAD}></Route>
                            <Route path="/ops/settings/aiSwitcher" exact component={AISwitcher}></Route>
                            <Route path="/ops/settings/aiSwitcher/:channel_code" exact component={AISwitcher}></Route>
                            <Route path="/ops/settings/aiSearch" exact component={AISearchSetting}></Route>
                            <Route path="/ops/settings/aiSearch/:channel_code" exact component={AISearchSetting}></Route>
                            {/* <Route path="/ops/settings/:type" exact component={CustomerServiceNotice}></Route> */}
                            <Route path="/ops/versions" exact component={Version}></Route>
                            <Route path="/ops/notification/channel" exact component={NotificationChannel}></Route>
                            <Route path="/ops/notification/scene" exact component={NotificationScene}></Route>
                            <Route path="/ops/notification/message" exact component={NotificationMessage}></Route>
                        </RequireRolesOr>
                    </div>
                </div>
            </RequireRolesOr>
        </Router>
    }
}


const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Operation)