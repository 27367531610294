import { Form, Radio, Button, message } from "antd"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Menu as MenuAction } from "../../store/global/actions"
import { withRouter } from "react-router-dom"
import ChannelsSelection from "./components/ChannelsSelection"
import { Tooltip } from "antd"

const INFORMATION_KEY = "aiSearch"

function AISearchSetting(props) {
    let [data, setData] = useState(null)
    let [channel, setChannel] = useState(null)
    const [form] = Form.useForm()
    const channelCode = props.match?.params?.channel_code

    props.setActiveKey("SETTING-AI-SEARCH")

    useEffect(() => {
        const _channel = props.socket.channel(`operation:settings:${INFORMATION_KEY}`, { 
            channel_code: channelCode 
        })
        _channel.join()
        _channel.on("data", ({ data }) => {
            const jsonedData = JSON.parse((data[0] || { content: "{}" }).content)
            form.setFieldsValue(jsonedData)
            setData(jsonedData)
        })
        setChannel(_channel)

        return () => {
            !!_channel && _channel.leave()
        }
    }, [channelCode])

    const onSave = (values) => {
        const payload = channelCode ? { ...values, channel_code: channelCode } : values
        channel.push("set", { [INFORMATION_KEY]: JSON.stringify(payload) })
            .receive("ok", () => message.success("保存成功"))
    }

    const onClear = () => {
        channel.push("clear", { key: INFORMATION_KEY })
            .receive("ok", () => message.success("清除成功"))
    }

    return <div className="page settings" style={{ flexDirection: "column", display: "flex", height: "100%" }}>
        <h1>AI搜索设置</h1>

        <div style={{ display: "flex", flexGrow: 1 }}>
            {!!data &&
                <Form form={form} 
                    style={{ width: 600 }} 
                    initialValues={data} 
                    name="basic" 
                    labelCol={{ span: 8 }} 
                    wrapperCol={{ span: 16 }} 
                    autoComplete="off" 
                    onFinish={onSave}>
                    
                    <Form.Item label="渠道" rules={[{ required: true }]} help={!!channelCode && "注意：以下数据也采用渠道数据优先，否则使用默认数据。 这些数据默认也来自于默认数据"}>
                        <ChannelsSelection informationKey={INFORMATION_KEY} channelCode={channelCode} channel={channel} />
                    </Form.Item>

                    <Form.Item 
                        label="内容搜索AI决策" 
                        name="content_search_ai_policy" 
                        rules={[{ required: true, message: '不能为空' }]}>
                        <Radio.Group>
                            <Radio value="no_result">无准确结果时使用</Radio>
                            <Radio value="vip">会员可用</Radio>
                            <Radio value="free">免费使用</Radio>
                            <Radio value="close">关闭</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item 
                        label="模型搜索AI决策" 
                        name="model_search_ai_policy" 
                        rules={[{ required: true, message: '不能为空' }]}>
                        <Radio.Group>
                            <Radio value="no_result">无准确结果时使用</Radio>
                            <Radio value="vip">会员可用</Radio>
                            <Radio value="free">免费使用</Radio>
                            <Radio value="close">关闭</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item wrapperCol={{ xs: { span: 8, offset: 8, } }}>
                        <Button.Group>
                            <Button size="small" type="primary" htmlType="submit">保存</Button>
                            {!!channelCode && <Tooltip title="清除渠道数据">
                                <Button size="small" onClick={onClear}>清除渠道数据</Button>
                            </Tooltip>}
                        </Button.Group>
                    </Form.Item>
                </Form>
            }
        </div>
    </div>
}

const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AISearchSetting)) 